import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import { Helmet } from "react-helmet";
import Markdown from "markdown-to-jsx";

// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PortabilityForm from "./PortabilityForm";
import { browserWindow } from "util/localStorageHelper";
const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

class portability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signupModal: false,
    };
  }
  
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  getContentPort = () => {
    const { classes, data } = this.props;
    var contentPort = data.allStrapiEntities.edges.map((document, i) => {
      if (document.node.category === `what_is_portability`) {
        var content = ``;
        if (document.node.displayType === `text`) {
          content = (
            <GridItem>
              <h4 className={classes.cardTitle}>{document.node.title}</h4>
              <Markdown>{document.node.description.replace(stringSearchRegExp, STRHOSPITALCOUNT)}</Markdown>
            </GridItem>
          );
        } else if (document.node.displayType === `link`) {
          content = (
            <GridItem>
              <h4 className={classes.cardTitle}>
                <a href={document.node.url} rel="noopener noreferrer">
                  {document.node.summary}
                </a>
              </h4>
              <p className={classes.description}>{document.node.description}</p>
            </GridItem>
          );
        }

        return (
          <GridContainer key={`portability-entity-${i}`}>
            <GridItem
              xs={12}
              md={10}
              sm={10}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              {content}
            </GridItem>
          </GridContainer>
        );
      }
    });
    return contentPort;
  };
  getContentHowPort = () => {
    const { classes } = this.props;
    var content = ``;
    content = (
      <>
        Please contact the nearest office of Star or click to port your policy
        <a
          style={{ marginLeft: `5px`, textDecoration: `underline` }}
          // href="#"
          onClick={() => window.location.href = '/portability-plans'}
        >
          online
        </a>
        . The office can be located from Office
        <a
          style={{
            marginLeft: `5px`,
            marginRight: `5px`,
            textDecoration: `underline`
          }}
          target="_blank"
          href="/locate-us"
        >
          Locator
        </a>
        from our website <p></p>
        Proposal Form and Portability
        <a
          style={{
            marginLeft: `5px`,
            marginRight: `5px`,
            textDecoration: `underline`
          }}
          rel="noopener noreferrer"
          target="_blank"
          href="/sites/default/files/Portability-form-Revised-Cir-Mail-281218.pdf"
        >
          form
        </a>
        to be duly filled, signed and submitted along with the following
        documents,
        <ul>
          <li>Policy copies of the previous insurance.</li>
          <li>
            Pre Insurance medical examination reports, wherever, applicable.
            Medical examination will be done at our cost.
          </li>
        </ul>
        A policyholder desirous of porting his/her policy to another insurance
        company shall apply to such insurance company to port the entire policy
        along with all the members of the family, if any, at least 45 days
        before, but not earlier than 60 days from the premium renewal date of
        his/her existing policy.
      </>
    );

    return (
      <GridContainer>
        <GridItem
          xs={12}
          md={10}
          sm={10}
          className={`${classes.mlAuto} ${classes.mrAuto}`}
        >
          <CardBody>{content}</CardBody>
        </GridItem>
      </GridContainer>
    );
  };

  getNav = () => {
    const { data } = this.props;
    var navPillArr = data.allStrapiSections.edges.map(document => {
      var obj = {};
      let content;
      switch (document.node.strapiId) {
        case 277:
          content = this.getContentHowPort();
          break;
        case 276:
          content = this.getContentPort();
          break;
      }

      obj.tabButton = document.node.title;
      obj.tabContent = content;
      return obj;
    });
    return navPillArr;
  };

  render() {
    const { classes, data } = this.props;
    var Images = data.allStrapiImages.edges;
    var bwImage = Images[0];
    var clrImage = Images[1];
    return (
      <Layout
        image1={
          bwImage &&
          bwImage.node &&
          bwImage.node.content &&
          bwImage.node.content.childImageSharp.fluid
        }
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
          <title>
            Health Insurance Portability, Star Health Portability Facility
          </title>
        </Helmet>
        {process.env.ENABLE_HELMET == `true` && (
          <Helmet key="helmetTags">
            <link
              rel="canonical"
              href="https://www.starhealth.in/portability"
            />
            <meta
              name="title"
              content="Health Insurance Portability, Star Health Portability Facility"
            />
            <meta
              name="twitter:title"
              content="Health Insurance Portability, Star Health Portability Facility"
            />
            <meta
              name="description"
              content="Health Insurance Portability. If you want to go for a health insurance portability or from one plan to another plan of the same insurer provided it has to be maintained without any break, click here!"
            />
            <meta property="og:title" content="Health Insurance Portability, Star Health Portability Facility" />
            <meta property="og:description" content="Health Insurance Portability. If you want to go for a health insurance portability or from one plan to another plan of the same insurer provided it has to be maintained without any break, click here!" />
            <meta property="og:url" content="https://www.starhealth.in/portability"/>
            <meta name="Health Insurance Portability, Star Health Portability Facility" />
            <meta name="twitter:description" content="Health Insurance Portability. If you want to go for a health insurance portability or from one plan to another plan of the same insurer provided it has to be maintained without any break, click here!" />
            <meta property="twitter:url" content="https://www.starhealth.in/portability" />
          </Helmet>
        )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100, paddingBottom: `70px` }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem style={{ paddingTop: `70px` }}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{
                        float: `left`,
                        marginLeft: `auto`,
                        marginRight: `auto`,
                        textAlign: `center`
                      }}
                    >
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={() => window.location.href = '/portability-plans'}
                      >
                        <Button
                          round
                          color="primary"
                          className={`${classes.buttonfixport}`}
                        >
                          Want to port your policy
                        </Button>
                      </a>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ float: `left` }}
                    >
                      <NavPills
                        color="primary"
                        location={browserWindow.location}
                        tabs={this.getNav()}
                        alignCenter
                        variant={true}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        <PortabilityForm handleClose={(modal) => this.handleClose(modal)} signupModal={this.state.signupModal} />
      </Layout>
    );
  }
}
portability.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(customStaticPageStyle)(portability);

export const portabilityQuery = graphql`
  query portability {
    allStrapiSections(filter: { page: { eq: "portability" } }) {
      edges {
        node {
          strapiId
          id
          title
        }
      }
    }
    allStrapiEntities(
      filter: { category: { regex: "/port/" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          id
          title
          category
          url
          description
          summary
          displayType
          sequence
        }
      }
    }
    allStrapiImages(filter: { category: { eq: "portability" } }) {
      edges {
        node {
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
